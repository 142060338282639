import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Avatar,
  FormHelperText,
  Grid,
} from "@mui/material";

import { useContext } from "react";
import { RtlContext } from "./RtlContext";

import ar from "../locales/ar.json";
import en from "../locales/en.json";

const MAX_FILE_SIZE_MB = 5;

function FileUpload({ label, onFileChange, error, hint }) {
  const [preview, setPreview] = useState(null);
  const [fileName, setFileName] = useState("");
  const { isRtl } = useContext(RtlContext);

  const lang = isRtl ? ar : en;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setPreview(null);
      setFileName("");
      onFileChange(null, null);
      return;
    }

    // Check file size
    if (file.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
      const errorMessage = lang.fileSizeError.replace(
        "{{size}}",
        MAX_FILE_SIZE_MB
      );
      onFileChange(null, errorMessage); // Use the updated error message
      return;
    }
    const validImageTypes = ["image/jpeg", "image/png"];
    if (!validImageTypes.includes(file.type)) {
      onFileChange(null, lang.invalidFileTypeError); // Use localized error message
      return;
    }

    // Generate preview URL
    const fileURL = URL.createObjectURL(file);
    setPreview(fileURL);
    setFileName(file.name);

    // Notify parent component of the selected file
    onFileChange(file, null);
  };

  return (
    <Box mt={2} sx={{ border: "1px solid #ccc", borderRadius: 2, padding: 2 }}>
      {/* Hint above the upload section */}
      {hint && (
        <Typography variant="body2" color="textSecondary" mb={1}>
          {hint}
        </Typography>
      )}

      <Typography variant="h6" textAlign="center">
        {label}
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        mt={2}
      >
        {/* File Name and Preview */}
        <Grid item xs={6} textAlign="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body2" sx={{ marginRight: 2 }}>
              {fileName || lang.noFileChosen} {/* Use localized text */}
            </Typography>
            {preview && (
              <Avatar
                src={preview}
                alt={fileName}
                variant="rounded"
                sx={{ width: 50, height: 50 }}
              />
            )}
          </Box>
        </Grid>

        {/* Upload Button */}
        <Grid item xs={6} textAlign="center">
          <Button variant="contained" component="label">
            {lang.upload} {/* Use localized text */}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
        </Grid>
      </Grid>

      {/* Error Message */}
      {error && (
        <FormHelperText error sx={{ textAlign: "center", mt: 2 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
}

export default FileUpload;
